import React, { Fragment } from 'react';
import { withTranslation, Trans } from 'react-i18next'
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormLabel from '@material-ui/core/FormLabel';
import { Editor } from '@tinymce/tinymce-react';
import { Listing } from "../../../fragments/Listing";
import Grid from "../../../components/layout/Grid";
import TextField from "../../../components/form/TextField";
import CategoryTreeField from "../../../components/form/CategoryTreeField";
import {tinyMceAPIKey} from "../../../environment";
import ListingTab from "./ListingTab";
import {FormHelperText} from "@material-ui/core";

class BasicDetails extends ListingTab {

    render() {
        const { form, config } = this.props;
        const objectType = form.getField('ClassNameShort');

        console.log('objectType', objectType)

        return (
            <Fragment>
                <Grid container spacing={0} className='form-panels-container'>
                    <Grid item className="form-panels">
                        <Grid container spacing={0}>
                            <Grid item className="field-holder">
                                <TextField
                                    form={form}
                                    name="Title"
                                    variant="outlined"
                                    label={<Trans i18nKey="myListings.editView.title">Title</Trans>}
                                    fullWidth
                                />
                                <FormHelperText>
                                    <Trans i18nKey="myListings.editView.twitterHelp">
                                        Name of your organisation
                                    </Trans>
                                </FormHelperText>
                            </Grid>
                            <Grid item className="field-holder">
                                <FormLabel>
                                    <Trans i18nKey="myListings.editView.categories">
                                        Sub-categories
                                    </Trans>
                                </FormLabel>
                                <CategoryTreeField
                                    isEvent={objectType === 'Event'}
                                    threshold={10}
                                    onSelected={e => this.onCategoriesSelected(e)}
                                    checkedKeys={form.getField('Categories').map(cat => cat.ID)}
                                />
                            </Grid>
                            <Grid item className="field-holder">
                                <Autocomplete
                                    multiple
                                    id="tags-standard"
                                    options={config.PortalTags}
                                    defaultValue={form.getField('Tags')}
                                    getOptionLabel={(option) => option.Title}
                                    onChange={this.onTagsChange.bind(this)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Tags"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item className="field-holder">
                                <TextField
                                    form={form}
                                    name="Phone"
                                    variant="outlined"
                                    label={<Trans i18nKey="myListings.editView.phone">Phone</Trans>}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item className="field-holder">
                                <TextField
                                    form={form}
                                    name="MobilePhone"
                                    variant="outlined"
                                    label={<Trans i18nKey="myListings.editView.mobilePhone">Mobile Phone</Trans>}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item className="field-holder">
                                <TextField
                                    form={form}
                                    name="FreePhone"
                                    variant="outlined"
                                    label={<Trans i18nKey="myListings.editView.freePhone">Free Phone</Trans>}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item className="field-holder">
                                <TextField
                                    form={form}
                                    name="Website"
                                    variant="outlined"
                                    label={<Trans i18nKey="myListings.editView.website">Website</Trans>}
                                    fullWidth
                                />
                                <FormHelperText>
                                    <Trans i18nKey="myListings.editView.twitterHelp">
                                        Enter with https://www.
                                    </Trans>
                                </FormHelperText>
                            </Grid>
                            <Grid item className="field-holder">
                                <TextField
                                    form={form}
                                    name="Email"
                                    label={<Trans i18nKey="myListings.editView.email">Email</Trans>}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Grid>
                            {(objectType === 'Activity' || objectType === 'Accommodation') && (
                                <Grid item className="field-holder">
                                    <TextField
                                        form={form}
                                        name="BookingLink"
                                        variant="outlined"
                                        label={<Trans i18nKey="myListings.editView.bookingLink">Booking Link</Trans>}
                                        fullWidth
                                    />
                                </Grid>
                            )}
                            <Grid item className="field-holder">
                                <TextField
                                    onError={() => this.setState({ errorInContent: true })}
                                    form={form}
                                    name="Summary"
                                    label={<Trans i18nKey="myListings.editView.summary">Summary</Trans>}
                                    multiline
                                    rows={5}
                                    variant="outlined"
                                    fullWidth
                                    InputProps={{maxLength:200}}
                                />
                                <FormHelperText>
                                    <Trans i18nKey="myListings.editView.twitterHelp">
                                        Summary must not exceed 200 characters
                                    </Trans>
                                </FormHelperText>
                            </Grid>
                            <Grid item className="field-holder">
                                <TextField
                                    form={form}
                                    name="Facilities"
                                    variant="outlined"
                                    label={<Trans i18nKey="myListings.editView.facilities">Facilities</Trans>}
                                    fullWidth
                                    multiline
                                    rows={6}
                                />
                                <Trans i18nKey="myListings.editView.twitterHelp">
                                    Enter a new line for each
                                </Trans>
                            </Grid>
                            {(objectType === 'Activity' || objectType === 'Accommodation') && (
                                <Grid item className="field-holder">
                                    <TextField
                                        form={form}
                                        name="BestTimeToTravel"
                                        variant="outlined"
                                        label={<Trans i18nKey="myListings.editView.bestTimeToTravel">Best Time To Travel</Trans>}
                                        fullWidth
                                    />
                                    <FormHelperText>
                                        <Trans i18nKey="myListings.editView.twitterHelp">
                                            E.G: Mar - Oct
                                        </Trans>
                                    </FormHelperText>
                                </Grid>
                            )}
                            <Grid item className="field-holder">
                                <TextField
                                    form={form}
                                    name="AgeRestrictions"
                                    variant="outlined"
                                    label={<Trans i18nKey="myListings.editView.ageRestrictions">Age Restrictions</Trans>}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item className="field-holder">
                                <FormLabel>
                                    <Trans i18nKey="myListings.editView.content">
                                        Content
                                    </Trans>
                                </FormLabel>
                                <Editor
                                    apiKey={tinyMceAPIKey}
                                    plugins="wordcount"
                                    onEditorChange={content => this.onContentChange(content)}
                                    value={form.getField('Content')}
                                    init={{
                                        height: 400,
                                        plugins: "paste",
                                        paste_as_text: true,
                                        paste_remove_styles_if_webkit: true,
                                        paste_retain_style_properties: "all",
                                        menubar: false
                                    }}
                                    toolbar={'undo redo | bold italic'}
                                />
                                <FormHelperText>
                                    <Trans i18nKey="myListings.editView.twitterHelp">
                                        Add as much as details you can about your organisation.
                                    </Trans>
                                </FormHelperText>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {this.renderButtons()}
            </Fragment>
        );
    }

    onTagsChange = (event, values) => {
        const { form } = this.props;

        const Tags = [];
        if (values && values.length) {
            values.map(tag => Tags.push({ ID: tag.ID }));
        }
        form.setField({ Tags });
    }

    onMainCategorySelected(checked) {
        const { form } = this.props;

        if (checked && checked.length) {
            form.setField({ MainCategoryID: Number(checked[0]) });
        }
    }

    onCategoriesSelected(checked) {
        const { form } = this.props;

        let Categories = [];
        if (checked && checked.length) {
            checked.map(catID => Categories.push({ ID: catID}));
        }
        form.setField({ Categories });
    }

    onContentChange(Content) {
        const { form } = this.props;
        form.setField({ Content });
    }

}

export default (isEvent = false) => ({
    id: 'business',
    tabURL: `/${isEvent ? 'event' : 'listing'}/edit/:id/basic`,
    label: 'Basic Information',
    component: withTranslation()(BasicDetails),
    fragment: Listing(),
    validation: {
        required: ['Title', 'Summary']
    },
    handleButtons: true,
    onLoad: data => {},
    formatSaveData: (saveData, state) => {
        if (saveData && saveData.Categories) {
            const categories = [];
            state.Categories.forEach(function(category) {
                categories.push(category.ID);
            });
            saveData.Categories = categories;
        }
        if (saveData && saveData.Tags) {
            const Tags = [];
            state.Tags.forEach(function(tag) {
                Tags.push(tag.ID);
            });
            saveData.Tags = Tags;
        }
    }
});